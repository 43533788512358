@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@shared/design-system/styles/initial.css';

@layer base {
  :root {
    /* Global */

    --max-content: 1296px;

    /* Colors */

    --color-neutral-0: 0 0% 100%; /* #ffffff */
    --color-neutral-50: 0 0% 99%; /* #fcfcfc */
    --color-neutral-100: 0 0% 95%; /* #f2f2f2 */
    --color-neutral-200: 0 0% 91%; /* #e7e7e7 */
    --color-neutral-300: 0 0% 80%; /* #cbcbcb */
    --color-neutral-400: 0 0% 56%; /* #909090 */
    --color-neutral-500: 0 0% 23%; /* #3b3b3b */
    --color-neutral-600: 0 0% 7%; /* #111111 */

    --color-green-100: 115 90% 92%; /* #DAFDD7 */
    --color-green-600: 114 51% 41%; /* #3fa234 */

    /* Fonts */
    --font-primary: var(--font-body);
    --font-secondary: var(--cabinetGroteskFont);

    /* Button */
    --btn-radius-sm: var(--radius-md);
    --btn-radius-md: var(--radius-lg);
    --btn-font-weight: var(--font-weight-medium);

    --btn-primary-bg: hsl(var(--color-green-600));
    --btn-primary-hover: hsl(var(--color-green-600) / 0.9);
    --btn-primary-active: hsl(var(--color-green-600) / 0.9);
  }
}
