:root {
  /* General */

  /* Fonts */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 2rem;
  --font-size-4xl: 3.5rem;

  --radius-sm: 0.125rem;
  --radius-md: 0.25rem;
  --radius-lg: 0.5rem;

  --font-weight-normal: 400;
  --font-weight-medium: 500;

  /* Colors */

  --color-primary-50: 217 33% 98%; /* #f8fafc */
  --color-primary-100: 214 32% 97%; /* #f5f8fa */
  --color-primary-200: 213 27% 91%; /* #e7ebf0 */
  --color-primary-300: 215 25% 84%; /* #d1d8e0 */
  --color-primary-400: 215 20% 65%; /* #8c99a5 */
  --color-primary-500: 215 16% 47%; /* #5f6b78 */
  --color-primary-600: 215 25% 27%; /* #2d3845 */
  --color-primary-700: 215 25% 27%; /* #2d3845 */
  --color-primary-800: 217 33% 17%; /* #1a2634 */
  --color-primary-900: 222 47% 11%; /* #0c1524 */

  /* Button */
  --btn-text: var(--font-size-md);
  --btn-font-family: var(--font-primary);
  --btn-font-weight: var(--font-weight-normal);

  --btn-h-sm: 2rem;
  --btn-h-md: 3rem;

  --btn-px-sm: 0.5rem;
  --btn-px-md: 1rem;

  --btn-radius-sm: 1000px;
  --btn-radius-md: 1000px;

  --btn-primary-bg: hsl(var(--color-primary-900));
  --btn-primary-text: hsl(var(--color-primary-50));
  --btn-primary-hover: hsl(var(--color-primary-900) / 0.9);
  --btn-primary-active: hsl(var(--color-primary-900) / 0.8);
  --btn-primary-disabled: hsl(var(--color-primary-900) / 0.5);

  --btn-secondary-bg: hsl(var(--color-primary-100));
  --btn-secondary-text: hsl(var(--color-primary-900));
  --btn-secondary-hover: hsl(var(--color-primary-200));
  --btn-secondary-active: hsl(var(--color-primary-200) / 0.9);

  /* Card */
  --card-bg: hsl(var(--color-primary-50));
  --card-radius: var(--radius-lg);
  --card-border: hsl(var(--color-primary-100));
  --card-text: hsl(var(--color-primary-900));
  --card-shadow: 0 0 0 1px hsl(var(--color-primary-100));

  /* Option */
  --option-px: 32px;
  --option-py: 24px;
  --option-radius: var(--radius-md);
  --option-border: hsl(var(--color-primary-100));
  --option-bg: hsl(var(--color-primary-50));
  --option-text: hsl(var(--color-primary-900));

  /* Progress */
  --progress-bg: hsl(var(--color-primary-100));
  --progress-indicator: hsl(var(--color-primary-900));
  --progress-height: 0.25rem;
  --progress-radius: var(--radius-md);

  /* Input */
  --input-bg: hsl(var(--color-primary-50));
  --input-border: hsl(var(--color-primary-900));
  --input-font-size: var(--font-size-sm);
  --input-text: hsl(var(--color-primary-900));
  --input-placeholder: hsl(var(--color-primary-400));
  --input-px: 0.75rem;
  --input-py: 0.5rem;

  /* Dialog */
  /* NOTE: replace bg color with variable */
  --dialog-overlay-bg: rgba(0, 0, 0, 0.4);
}
